



















import { computed } from '@vue/composition-api';

export default {
  components: { AButton: () => import('../atoms/AButton.vue') },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    event: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const dialogVal = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const eventVal = computed({
      get: () => props.event,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return { dialogVal, eventVal };
  }
};
